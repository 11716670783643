<template>
    <b-card
        v-if="$route.name == 'OutgoingInvoice edit'"
    >
      <!-- Button: Edit -->
      <button-edit
          @click="$emit('submitForm')"
          :block="true"
      />
    </b-card>

    <b-card v-else>
      <!-- Button: Send Invoice -->
      <button-validate
          v-if="outgoingInvoice.billNumber == null"
          @click="$emit('validateOutgoingInvoice')"
          :block="true"
          class="mb-75"
      />

      <!-- Button: Edit -->
      <button-edit
          v-if="outgoingInvoice.billNumber == null"
          @click="$router.push({ name: 'OutgoingInvoice edit', params: { outgoingInvoiceId: $route.params.outgoingInvoiceId } })"
          :block="true"
      />

      <!-- Button: Add payment -->
      <button-add
          v-if="outgoingInvoice.billNumber != null"
          text="addPayment"
          :withIcon="false"
          @click="$emit('addPayment')"
          :block="true"
          class="mb-75"
      />

      <!--          Duplicate -->
      <button-duplicate
          @click="$emit('duplicateOutgoingInvoice')"
          :block="true"
      />

      <!--          Delete -->
      <button-delete
          v-if="outgoingInvoice.billNumber == null"
          @click="$emit('removeOutgoingInvoice')"
          :block="true"
      />

      <!-- Button: Download -->
      <button-download
          @click="$emit('downloadOutgoingInvoice')"
          :block="true"
          class="mt-4"
      />

      <!-- Button: Linked offer -->
      <button-view
          v-if="linkedOffer != null"
          @click="$router.push({ name: 'Offer view', params: { offerId: linkedOffer.id } })"
          title="LinkedOffer"
          :block="true"
      />

    </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'

import Ripple from 'vue-ripple-directive'
import ButtonEdit from '@/components/button/Edit'
import ButtonValidate from '@/components/button/Validate'
import ButtonAdd from '@/components/button/Add'
import ButtonDuplicate from '@/components/button/Duplicate'
import ButtonDelete from '@/components/button/Delete'
import ButtonDownload from '@/components/button/Download'
import ButtonView from '@/components/button/View'

export default {
  directives: {
    Ripple,
  },
  components: {
    ButtonEdit,
    ButtonValidate,
    ButtonAdd,
    ButtonDuplicate,
    ButtonDelete,
    ButtonDownload,
    ButtonView
  },
  props: {
    outgoingInvoice: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const linkedOffer = computed(() => {
      let concernedProduct = props.outgoingInvoice.products.find(p => p.offer != null)

      if (typeof concernedProduct != 'undefined') return concernedProduct.offer
      else return null
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed
      linkedOffer,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () { },
  created () { }
}
</script>

<style lang="scss">

</style>