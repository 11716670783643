<template>
  <section class="invoice-preview-wrapper">
    <!--    {{ outgoingInvoice }}-->

    <b-row
        v-if="isReady"
        class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
          cols="12"
          xl="9"
          md="8"
      >

        <view-invoice
            :invoice="outgoingInvoice"
        />
      </b-col>

      <!-- Right Col: Card -->
      <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
      >
        <buttons-col
            :outgoing-invoice="outgoingInvoice"
            @validateOutgoingInvoice="validateOutgoingInvoiceLocal(outgoingInvoice)"
            @duplicateOutgoingInvoice="duplicateOutgoingInvoice(outgoingInvoice)"
            @removeOutgoingInvoice="deleteOutgoingInvoiceAlert(outgoingInvoice)"
            @downloadOutgoingInvoice="downloadOutgoingInvoice(outgoingInvoice)"
            @addPayment="addPayment('outgoingInvoice', outgoingInvoice)"
        />

        <payments-list
            :outgoing-invoice="outgoingInvoice"
            @editPayment="selectPayment('outgoingInvoice', outgoingInvoice, $event)"
            @deletePayment="deletePaymentAlert('outgoingInvoice', outgoingInvoice, $event)"
        />
      </b-col>
    </b-row>


    <!--    &lt;!&ndash; modal outgoing invoice&ndash;&gt;-->
    <!--    <modal-outgoing-invoice-->
    <!--        :outgoingInvoice="currentOutgoingInvoice"-->
    <!--        :title="outgoingInvoiceModalTitle"-->
    <!--        :isOpen.sync="outgoingInvoiceModalShow"-->
    <!--    />-->


    <!-- modal outgoing invoice-->
    <modal-outgoing-invoice
        :outgoingInvoice.sync="currentOutgoingInvoice"
        :title="outgoingInvoiceModalTitle"
        :isOpen.sync="outgoingInvoiceModalShow"
        @submitValidatedOutgoingInvoice="submitValidatedOutgoingInvoiceLocal"
    />

    <!-- modal payment-->
    <modal-payment
        :payment.sync="currentPayment"
        :title="paymentModalTitle"
        :isOpen.sync="paymentModalShow"
        @submitValidatedPayment="submitValidatedPaymentLocal"
    />

  </section>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isObject } from '@/utils/utils'
import { mixinOutgoingInvoices } from './mixinOutgoingInvoices'
import { mixinPayments } from '../../../mixins/mixinPayments'

import store from '@/store'
import router from '@/router'
import ViewInvoice from '@/components/views/Invoice'
import ButtonsCol from './components/_buttons_col'
import PaymentsList from './components/_payments_list'

export default {
  components: {
    ViewInvoice,
    ButtonsCol,
    PaymentsList
  },
  mixins: [mixinOutgoingInvoices, mixinPayments],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const outgoingInvoice = computed(() => {
      return store.getters['outgoingInvoice/getOutgoingInvoice'](router.history.current.params.outgoingInvoiceId)
    })

    const isReady = computed(() => (outgoingInvoice.value &&
        isObject(outgoingInvoice.value.billingAddress) &&
        isObject(outgoingInvoice.value.deliveryAddress)))

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      outgoingInvoice,
      isReady,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>