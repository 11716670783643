<template>
  <b-card>
    <template #header>
      <div class="d-flex justify-content-between">
        <span class="mb-0">{{ outgoingInvoice.bankOperations.length }} {{ $tc('payment', outgoingInvoice.bankOperations.length) }}
        </span>
      </div>
      <div
          :class="paid>=0?'text-success':'text-danger'"
      >
        {{ currency(paid) }}
      </div>
    </template>

    <b-row>

      <b-col
          v-for="(payment, index) in outgoingInvoice.bankOperations"
          cols="12"
      >
        <payment-content
            :payment="payment"
            @edit="$emit('editPayment', payment)"
            @delete="$emit('deletePayment', payment)"
        />
      </b-col>

    </b-row>
  </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency } from '../../../../utils/filter'

import PaymentContent from '../../../../components/card/components/PaymentContent'

export default {
  components: {
    PaymentContent
  },
  props: {
    outgoingInvoice: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const paid = computed(() => {
      let output = 0

      props.outgoingInvoice.bankOperations.forEach(bo =>
          output += bo._balance
      )

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      currency,

      // Data

      // Computed
      paid,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>